@import "/src/constants";

#provenance-hash-section {
  background-color: rgba($dark-chocolate, 0.95);
  max-width: unset;

  .provenance-section-inner {
    margin: 0 auto;
    padding: 64px 32px;
    overflow: hidden;
  }

  h1 {
    color: $beige;
    margin: 0 0 64px;

    span {
      color: $blue;
    }
  }

  #p-hash-desc {
    color: $beige;
    font-size: 20px;
    line-height: 1.21;
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
  }

  #wizard-ape {
    display: block;
    margin: 32px auto;
    max-width: 500px;
    width: 100%;
    height: auto;
    border-radius: $standard-border-radius;
  }

  #p-hash {
    font-size: 20px;
    line-height: 1.21;
    font-family: monospace;
    color: $blue;
    word-wrap: break-word;
    margin: 32px auto 0;
    text-align: center;
    max-width: 500px;
  }
}

@media (max-width: 800px) {
  #provenance-hash-section {
    .provenance-section-inner {
      padding: 64px 0;
    }

    h1 {
      margin-bottom: 64px;
    }

    #p-hash-desc {
      font-size: 18px;
    }

    #wizard-ape {
      margin: 32px auto;
    }

    #p-hash {
      font-size: 18px;
      margin: 32px auto;
    }
  }
}
