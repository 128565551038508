#provenance-hash-section {
  max-width: unset;
  background-color: #1e2019f2;
}

#provenance-hash-section .provenance-section-inner {
  margin: 0 auto;
  padding: 64px 32px;
  overflow: hidden;
}

#provenance-hash-section h1 {
  color: #e2efb3;
  margin: 0 0 64px;
}

#provenance-hash-section h1 span {
  color: #35a4cd;
}

#provenance-hash-section #p-hash-desc {
  color: #e2efb3;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 1.21;
}

#provenance-hash-section #wizard-ape {
  max-width: 500px;
  width: 100%;
  height: auto;
  border-radius: 4%;
  margin: 32px auto;
  display: block;
}

#provenance-hash-section #p-hash {
  color: #35a4cd;
  word-wrap: break-word;
  text-align: center;
  max-width: 500px;
  margin: 32px auto 0;
  font-family: monospace;
  font-size: 20px;
  line-height: 1.21;
}

@media (max-width: 800px) {
  #provenance-hash-section .provenance-section-inner {
    padding: 64px 0;
  }

  #provenance-hash-section h1 {
    margin-bottom: 64px;
  }

  #provenance-hash-section #p-hash-desc {
    font-size: 18px;
  }

  #provenance-hash-section #wizard-ape {
    margin: 32px auto;
  }

  #provenance-hash-section #p-hash {
    margin: 32px auto;
    font-size: 18px;
  }
}

/*# sourceMappingURL=index.88bf0ecc.css.map */
